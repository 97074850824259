import { DialogController }                           from 'aurelia-dialog';
import { bindable, inject }                           from 'aurelia-framework';
import { PLATFORM }                                   from 'aurelia-pal';
import { BaseFormViewModel }                          from 'base-form-view-model';
import { FormulationStudyCompositionDataFormSchema }  from 'modules/bituminous-mixtures/mixtures/formulation-studies/composition-data/form-schema';
import { FormulationStudyCompositionDatasRepository } from 'modules/bituminous-mixtures/mixtures/formulation-studies/composition-data/services/repository';
import { CompositionDataRepository }                  from 'modules/management/bituminous-mixtures/composition-data/services/repository';
import { CompositionField }                           from 'modules/management/models/composition-field';
import { AppContainer }                               from 'resources/services/app-container';

@inject(AppContainer, DialogController, FormulationStudyCompositionDatasRepository, FormulationStudyCompositionDataFormSchema, CompositionDataRepository)
export class EditFormulationStudyCompositionDataModal extends BaseFormViewModel {

    formId = 'bituminous-mixtures.mixtures.formulation-studies.composition-data.edition-form';

    @bindable alert = {};
    model           = {};
    schema          = {};

    @bindable settings = {
        title:   'form.title.edit-record',
        size:    'modal-md',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     * @param formSchema
     * @param compositionDataRepository
     */
    constructor(appContainer, dialogController, repository, formSchema, compositionDataRepository) {
        super(appContainer);

        this.dialogController          = dialogController;
        this.repository                = repository;
        this.formSchema                = formSchema;
        this.compositionDataRepository = compositionDataRepository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'bituminous-mixtures.mixtures.formulation-studies.manage',
            'bituminous-mixtures.mixtures.formulation-studies.edit',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/formulation-studies/composition-data/form.html');
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.bmFormulationStudyId = params.model.id;
        this.bmFormulationStudy   = params.model;

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params.row);

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);

        await this.fillCompositionData();
    }

    /**
     * Fill composition data
     *
     * @return {Promise<void>}
     */
    async fillCompositionData() {
        await this.compositionDataRepository.fields(this.model.composition_data_id).then((response) => {
            response.map((field) => {

                this.schema[0].push({
                    type:     CompositionField.getFormFieldType(field.id),
                    key:      field.field_name,
                    label:    field.name,
                    size:     6,
                    required: false,
                });
            });
        });
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.model.lot_intervention_id = this.bmFormulationStudy.lot_intervention_id;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response);
                this.initialModel.assign(this.model);

                if (response.status === true) {
                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
